<template>
  <CardContainer :title="title" v-if="stats">
    <template slot="chart">
      <CChartBar
        :datasets="chartData"
        :labels="chartLabel"
        :options="chartOption"
      />
    </template>
  </CardContainer>
</template>

<script>
import { mapState } from 'vuex'
import CardContainer from '@/containers/DashboardCard.vue'
import { CChartBar } from '@coreui/vue-chartjs'

export default {
  name: 'CompCardSimpleBarChart',
  components: {
    CardContainer,
    CChartBar
  },
  props: {
    title: {type:String,default:'Data'},
    stats: {type:Array},
    labels: {type:Array},
    colors: {type:Array},
    y_unit: {type:String,default:''},
    aspect_ratio: {type:Number},
    stack: {type:Boolean,default:false},
    stackNames: {type:Array},
    y_stack: {type:Boolean,default:false}
  },
  computed: {
    ...mapState([
      'capability'
    ]),
    chartData() {
      const rtn = [];
      for (const i in this.stats) {
        rtn.push({
          label: this.labels[i],
          data: this.stats[i].map(el => el.value),
          backgroundColor: this.colors[i],
          stack: this.stack?this.stackNames[i]:undefined
        });
      }
      return rtn;
    },
    chartLabel() {
      let rtn = [];
      let stat = this.stats[0];
      if (stat.length === 24) {
        for (let i in stat) {
          const d = new Date(stat[i].x);
          rtn.push(this.$utility.zonedDateTimeStr(d, this.capability.user_profile.timezone, 'H'));
        }
      } else if (stat.length === 7) {
        rtn = [
          this.$t('cloud.title.dow_sun'),
          this.$t('cloud.title.dow_mon'),
          this.$t('cloud.title.dow_tue'),
          this.$t('cloud.title.dow_wed'),
          this.$t('cloud.title.dow_thu'),
          this.$t('cloud.title.dow_fri'),
          this.$t('cloud.title.dow_sat')
        ];
      } else {
        for (let i in stat) {
          if (i === 0 || i % 5 === 4) {
            rtn.push(+i+1);
          } else {
            rtn.push('');
          }
        }
      }
      return rtn;
    },
    chartOption() {
      return {
        legend: {
          position: 'top', // top, right, bottom, left
          align: 'center', // 'start', 'center', 'end'
          labels: {
            fontSize: 12
          }
        },
        scales: {
          xAxes: [{
            stacked: this.stack,
            gridLines: {
              drawOnChartArea: false
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.y_unit
            },
            stacked: this.y_stack,
            ticks: {
              beginAtZero: true,
              // stepSize: 1
              // maxTicksLimit: 5,
              // stepSize: Math.ceil(250 / 5),
              // max: 250
              suggestedMax: 10
            },
            gridLines: {
              display: true
            }
          }]
        },
        aspectRatio: this.aspect_ratio
      };
    }
  }
}
</script>