//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CardSimpleNumber from '@/components/cards/SimpleNumber.vue';
import CardPieCharts from '@/components/cards/PieCharts.vue';
import CardBarCharts from '@/components/cards/BarCharts.vue';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
export default {
  name: 'RetailOccupancyDownload',
  components: {
    ConfirmModal: ConfirmModal,
    CardSimpleNumber: CardSimpleNumber,
    CardPieCharts: CardPieCharts,
    CardBarCharts: CardBarCharts
  },
  computed: {
    todayTotalIncrease: function todayTotalIncrease() {
      return this.stats.today_total - this.stats.today_total_yesterday;
    },
    todayMaleIncrease: function todayMaleIncrease() {
      return this.stats.today_male - this.stats.today_male_yesterday;
    },
    todayFemaleIncrease: function todayFemaleIncrease() {
      return this.stats.today_female - this.stats.today_female_yesterday;
    },
    genderDist: function genderDist() {
      var dist_str = null;
      var total = this.stats.today_male + this.stats.today_female;

      if (total <= 0) {
        return "-";
      }

      var male_ratio = parseInt(this.stats.today_male / total * 100);

      if (male_ratio < 40) {
        dist_str = "".concat(this.$t('cloud.title.gender_domin_female'));
      } else if (male_ratio < 60) {
        dist_str = this.$t('cloud.title.gender_domin_even');
      } else {
        dist_str = "".concat(this.$t('cloud.title.gender_domin_male'));
      }

      return dist_str;
    },
    malePortion: function malePortion() {
      var total = this.stats.today_male + this.stats.today_female;
      if (total <= 0) return null;
      return parseInt(this.stats.today_male / total * 100);
    }
  },
  data: function data() {
    return {
      data_ready: false,
      loading: null,
      pdf: null,
      pdf_id: 1,
      stats: {},
      cycle: null
    };
  },
  mounted: function mounted() {
    this.readData();
  },
  methods: {
    readData: function readData() {
      var data = this.$route.query.p;
      var data_decoded = decodeURIComponent(escape(window.atob(data)));
      var data_parsed = JSON.parse(data_decoded);
      this.stats = data_parsed.stats;
      this.cycle = data_parsed.cycle;
      this.data_ready = true;
    },
    onDownloadPdf: function onDownloadPdf() {
      var _this = this;

      this.$refs.ConfirmModal.open(null, function () {
        _this.onPDF();
      });
    },
    onPDF: function onPDF() {
      var _this2 = this;

      this.loading = this.$loading({
        lock: true,
        text: this.$t('cloud.title.report_generating'),
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.now = new Date();
      var container = document.getElementById("ReportContainer".concat(this.pdf_id));

      if (!container) {
        this.pdf_id = 1;
        var filename = this.$utility.GetDateTimeStr("gender-$yyyy$mm$dd-$HH$MM$ss.pdf", this.now);
        this.pdf.save(filename);
        this.pdf = null;
        this.loading.close();
        return;
      }

      if (this.pdf) {
        this.pdf.addPage();
      } else {
        this.pdf = new JsPDF('l', 'mm', 'a4');
      }

      html2canvas(container, {
        imageTimeout: 5000,
        useCORS: true
      }).then(function (canvas) {
        var img = canvas.toDataURL('image/png');

        _this2.pdf.addImage(img, 'JPEG', 5, 5, 287, 200);

        _this2.pdf_id++;
        setTimeout(_this2.onPDF, 500);
      });
    },
    onDownloadJpg: function onDownloadJpg() {
      var _this3 = this;

      this.$refs.ConfirmModal.open(null, function () {
        _this3.onJpeg();
      });
    },
    onJpeg: function onJpeg() {
      var _this4 = this;

      this.loading = this.$loading({
        lock: true,
        text: this.$t('cloud.title.report_generating'),
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.now = new Date(); // const container = document.createElement('div');

      var container = this.$refs.ReportContainer0; // add option type to get the image version
      // if not provided the promise will return 
      // the canvas.

      html2canvas(container, {
        imageTimeout: 5000,
        useCORS: true
      }).then(function (canvas) {
        // container.appendChild(canvas)
        var img = canvas.toDataURL('image/png');

        var filename = _this4.$utility.GetDateTimeStr("gender-$yyyy$mm$dd-$HH$MM$ss.jpg", _this4.now);

        var link = document.createElement("a");
        link.setAttribute("href", img);
        link.setAttribute("download", filename);
        link.click();

        _this4.loading.close();
      });
    },
    onDownloadCsv: function onDownloadCsv() {
      var _this5 = this;

      this.$refs.ConfirmModal.open(null, function () {
        _this5.onCsv();
      });
    },
    onCsv: function onCsv() {
      var csv_array = [];
      var header_line = [];
      header_line.push(this.$t('cloud.title.log_at'));
      header_line.push(this.$t('cloud.title.occupancy_visitor_count'));
      header_line.push(this.$t('cloud.title.gender_male'));
      header_line.push(this.$t('cloud.title.gender_femail'));

      for (var i = 0; i < this.stats.total.length; i++) {
        var data_line = {};
        var time = this.$utility.GetDateTimeStr("$yyyy-$mm-$dd $HH:$MM", new Date(this.stats.total[i].x));
        data_line[this.$t('cloud.title.log_at')] = time;
        var count = this.stats.total[i].value;
        data_line[this.$t('cloud.title.occupancy_visitor_count')] = count;
        var male = this.stats.male[i].value;
        var female = this.stats.female[i].value;
        data_line[this.$t('cloud.title.gender_male')] = male;
        data_line[this.$t('cloud.title.gender_female')] = female;
        csv_array.push(data_line);
      }

      this.$utility.DownloadCSV(csv_array, 'gender-$yyyy$mm$dd_$HH$MM$ss.csv');
    }
  }
};